import { useLocation } from "@reach/router"
import React, { useEffect, useState } from "react"

const TableOfContent = ({ tocName, setIsTocContentExist }) => {
  const location = useLocation()
  const { hash } = location

  const [nestedHeadings, setNestedHeadings] = useState([])
  const [currentHash, setCurrentHash] = useState("")

  useEffect(() => {
    let locationUrl = location.href
    if (location.hash) {
      locationUrl = locationUrl.replace(location.hash, "")
    }

    const headingElements = Array.from(document.querySelectorAll("h1, h2"))

    if (headingElements && headingElements.length > 0) {
      const newNestedHeadings = getNestedHeadings(headingElements, locationUrl)
      // console.log("newNestedHeadings", newNestedHeadings)
      setNestedHeadings(newNestedHeadings)
      setIsTocContentExist(true)
    } else {
      setIsTocContentExist(false)
    }
  }, [])

  const [activeId, setActiveId] = useState()
  useIntersectionObserver(setActiveId)

  return (
    <>
      <div className="table-of-content">
        {tocName && <div className="heading">{tocName ? tocName : ""}</div>}
        <Headings
          headings={nestedHeadings}
          activeId={activeId}
          currentHash={currentHash}
          setCurrentHash={setCurrentHash}
        />
      </div>
    </>
  )
}

const getNestedHeadings = (headingElements, locationUrl) => {
  const nestedHeadings = []

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading

    if (heading.nodeName === "H1") {
      heading.setAttribute("data-id", convertStringToKebabCase(title))
      nestedHeadings.push({
        id: convertStringToKebabCase(title),
        title,
        items: [],
      })

      // heading.innerHTML =
      //   heading.innerHTML +
      //   `<a class="link" href="${locationUrl}#${convertStringToKebabCase(
      //     heading.innerHTML
      //   )}"></a>`
    } else if (heading.nodeName === "H2" && nestedHeadings.length > 0) {
      heading.setAttribute("data-id", convertStringToKebabCase(title))
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id: convertStringToKebabCase(title),
        title,
      })

      // heading.innerHTML =
      //   heading.innerHTML +
      //   `<a class="link" href="${locationUrl}#${convertStringToKebabCase(
      //     heading.innerHTML
      //   )}"></a>`
    }
  })

  return nestedHeadings
}

const Headings = ({ headings, activeId, currentHash, setCurrentHash }) => (
  <ul className="table-content">
    {headings.map((heading, index) => (
      <li key={index} className={heading.id === currentHash ? "active" : ""}>
        {console.log("headings", headings)}
        <a
          href={`#${heading.id}`}
          onClick={event => {
            // event.preventDefault()
            onLinkClick(heading.id, setCurrentHash)
          }}
        >
          {heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child, index) => (
              <li
                key={index}
                className={child.id === currentHash ? "active" : ""}
              >
                <a
                  href={`#${child.id}`}
                  onClick={event => {
                    onLinkClick(child.id, setCurrentHash)
                  }}
                >
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
)

const onLinkClick = (item, setCurrentHash) => {
  if (item) {
    setCurrentHash(item)
    const el = document.querySelector("[data-id=" + item + "]")
    scrollToContent(el)
  }
}

const scrollToContent = el => {
  const headerHeight = document.querySelector(".header").offsetHeight

  if (window) {
    const elTopPos =
      el.getBoundingClientRect().top + window.scrollY - headerHeight - 20
    window.scrollTo(0, elTopPos)
  }
}

const useIntersectionObserver = setActiveId => {
  const headingElementsRef = React.useRef({})
  useEffect(() => {
    const callback = headings => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement
        return map
      }, headingElementsRef.current)

      const visibleHeadings = []
      Object.keys(headingElementsRef.current).forEach(key => {
        const headingElement = headingElementsRef.current[key]
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
      })

      const getIndexFromId = id =>
        headingElements.findIndex(heading => heading.id === id)

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id)
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        )
        setActiveId(sortedVisibleHeadings[0].target.id)
      }
    }

    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px 0px -40% 0px",
    })

    const headingElements = Array.from(document.querySelectorAll("h1, h2"))

    headingElements.forEach(element => observer.observe(element))

    return () => observer.disconnect()
  }, [setActiveId])
}

const convertStringToKebabCase = data => {
  const input = data
  const output = input
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .split(" ")
    .join("-")
  return output
}

export default TableOfContent
