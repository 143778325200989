exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-explorer-tsx": () => import("./../../../src/pages/api-explorer.tsx" /* webpackChunkName: "component---src-pages-api-explorer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-references-ndc-apis-api-tsx": () => import("./../../../src/pages/references/ndc-apis/api/[...].tsx" /* webpackChunkName: "component---src-pages-references-ndc-apis-api-tsx" */),
  "component---src-pages-references-platform-apis-api-tsx": () => import("./../../../src/pages/references/platform-apis/api/[...].tsx" /* webpackChunkName: "component---src-pages-references-platform-apis-api-tsx" */),
  "component---src-pages-support-change-log-tsx": () => import("./../../../src/pages/support/change-log.tsx" /* webpackChunkName: "component---src-pages-support-change-log-tsx" */),
  "component---src-pages-support-faq-tsx": () => import("./../../../src/pages/support/faq.tsx" /* webpackChunkName: "component---src-pages-support-faq-tsx" */),
  "component---src-templates-airlines-page-template-tsx": () => import("./../../../src/templates/airlines-page-template.tsx" /* webpackChunkName: "component---src-templates-airlines-page-template-tsx" */),
  "component---src-templates-concepts-page-template-tsx": () => import("./../../../src/templates/concepts-page-template.tsx" /* webpackChunkName: "component---src-templates-concepts-page-template-tsx" */),
  "component---src-templates-faq-page-template-tsx": () => import("./../../../src/templates/faq-page-template.tsx" /* webpackChunkName: "component---src-templates-faq-page-template-tsx" */),
  "component---src-templates-landing-page-template-tsx": () => import("./../../../src/templates/landing-page-template.tsx" /* webpackChunkName: "component---src-templates-landing-page-template-tsx" */),
  "component---src-templates-sample-page-template-tsx": () => import("./../../../src/templates/sample-page-template.tsx" /* webpackChunkName: "component---src-templates-sample-page-template-tsx" */),
  "component---src-templates-tutorial-page-template-tsx": () => import("./../../../src/templates/tutorial-page-template.tsx" /* webpackChunkName: "component---src-templates-tutorial-page-template-tsx" */),
  "component---src-templates-usage-page-template-tsx": () => import("./../../../src/templates/usage-page-template.tsx" /* webpackChunkName: "component---src-templates-usage-page-template-tsx" */)
}

