/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./language-tabs.css"
import { wrapRootElement as wrap } from "./wrap-root-element"
import "gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css"
var FlexSearch = require("flexsearch")
export const wrapRootElement = wrap

var indexObj = new Array()
export const onClientEntry = () => {
  fetch(__PATH_PREFIX__ + "/flexsearch_index.json")
    .then(function (response) {
      return response.json()
    })
    .then(function (index) {
      Object.keys(index).forEach(lng => {
        Object.keys(index[lng].index).forEach(idx => {
          var index_ = index[lng].index[idx]
          indexObj = new FlexSearch(index_.attrs)
          indexObj.import(index_.values)
          index_.values = indexObj
        })
      })
      window.__FLEXSEARCH__ = index
      console.log("index: ", index)
    })
    .catch(function (e) {
      return console.error("Failed fetch search index", e)
    })
}
